require('./bootstrap');

require('floatthead/dist/jquery.floatThead');

require('dropzone/dist/dropzone');
require('leaflet/dist/leaflet.js');
require('leaflet-rastercoords')


require('bootstrap-autocomplete/dist/latest/bootstrap-autocomplete.min');

